const headerMenu = document.getElementById('header-menu')
const headerMenuButton = document.getElementById('menu-toggle-btn')
const header = document.getElementsByTagName('header')[0]
console.log('header :', header)

headerMenuButton.addEventListener('click', () => {
  if (headerMenu.style.display === 'flex') {
    headerMenu.style.display = 'none'
    headerMenu.style.visibility = 'hidden'
    headerMenu.style.opacity = 0
    headerMenuButton.innerHTML = `<i data-feather="menu"></i>`
    header.style.backgroundColor = 'rgba(255,255,255,0)'
  } else {
    headerMenu.style.display = 'flex'
    headerMenu.style.visibility = 'visible'
    headerMenu.style.opacity = 1
    headerMenuButton.innerHTML = `<i data-feather="x"></i>`
    header.style.backgroundColor = 'var(--white)'
  }
  feather.replace()
})

window.onscroll = () => {
  if (
    document.body.scrollTop >= 16 ||
    document.documentElement.scrollTop >= 16
  ) {
    header.classList.add('header-scroll')
  } else {
    header.classList.remove('header-scroll')
  }
}
